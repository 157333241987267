import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { Row, Col, FormGroup, Label, Input } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { get, isArray, uniqBy, orderBy } from 'lodash';
import { EmploymentRate } from '../../../constants/Position';
import Switch from '../../input/Switch';
import Select from '../../input/Select';
import { validateImgUrl } from '../../../helpers/image';
import { renderErrorField } from '../../../helpers/form';
import InputLoaderWrapper from '../../input/InputLoader';
import { setDepartmentsOptions, getDepartmentsOptions } from '../../../store/thunks/settings.thunks';
import { log } from '../../../helpers/utils';
import SelectV2 from '../../@v2/Select/Select';
import { selectUser } from '../../../store/selectors/user.selector';
import { selectOptions } from '../../../store/selectors/settings.selector';
import { useSelector as useSelectorToolkit, dispatch as dispatchToolkit } from '../../../store';
import { browseDepartments } from '../../../store/api/departments.api';
import TeamMemberList from '../../@v2/TeamMemberList';

const defaultAvatarURL = '/images/avatars/default-avatar.svg';
const azureImageContainer = `${global?.appConfig?.image_service_base_url}/loginpictures`;

const NotVisiblePosition = ({ state, setState, isValidating, loading }) => {
  const { t } = useTranslation();
  const options = useSelectorToolkit(selectOptions);
  const jobFunctions = get(options, 'jobFunctions', []);
  const usersList = get(options, 'recruiters', []);
  const departmentsOptions = get(options, 'departments', []).map((item) => ({
    ...item,
    label: item.name,
    value: item.id,
  }));
  const currentUser = useSelectorToolkit(selectUser);
  const [touchedFields, setTouchedFields] = useState({});
  const [zIndex, setZIndex] = useState(2);
  const [recruiterDefaultValue, setRecruiterDefaultValue] = useState(null);
  const [recruiterDefaultValueLoading, setRecruiterDefaultValueLoading] = useState(true);

  const users = useMemo(
    () =>
      usersList.map((item) => ({
        ...item,
        id: 0,
        userId: item.id,
        value: item.id,
        label: item.name,
        imageUrl: item.pictureFileExt
          ? validateImgUrl(`${azureImageContainer}/${item.loginId}-${item.pictureGuid}.${item.pictureFileExt}`, 'user')
          : defaultAvatarURL,
        positionId: state.positionId,
      })),
    [usersList, state.positionId]
  );

  const teamMembersValue = useMemo(
    () =>
      uniqBy(
        state?.teamMembers?.map((item) => ({
          ...item,
          id: item.id || item.userId,
          teamMemberId: item.teamMemberId || item.id,
          userId: item.userId || item.id,
          name: item.name,
        })),
        'userId'
      ),
    [state?.teamMembers, users]
  );

  const jobFunctionsOptions = useMemo(() => {
    const mappedOptions = jobFunctions.map((code) => ({
      label: t(`jobFunction.${code}`),
      value: code,
    }));
    return orderBy(mappedOptions, 'label');
  }, [jobFunctions]);

  const employmentRateOptions = useMemo(
    () =>
      EmploymentRate.map((item) => ({
        ...item,
        label: t(item.label),
      })),
    [EmploymentRate]
  );

  const canEditTeamOrRecruit = useMemo(() => {
    if (state && state.positionId) {
      if (currentUser.permissionLevel !== 0) return false;
      return !!teamMembersValue.find(({ userId }) => userId === currentUser.userId);
    }
    return false;
  }, [state, currentUser, teamMembersValue]);

  useEffect(() => {
    if (!recruiterDefaultValue && !loading.data) {
      // will recruiter always have default value? even if not admin?
      const defaultRecruiter = usersList[0] || {};
      const defaultRecruiterId = state.recruiterId || defaultRecruiter.id;
      setState('recruiterId', defaultRecruiterId);
      const user = users.find((item) => item.value === defaultRecruiterId);
      setRecruiterDefaultValue(
        user && user !== 'undefined'
          ? user
          : {
              id: defaultRecruiter.id,
              name: defaultRecruiter.name,
            }
      );
      setRecruiterDefaultValueLoading(false);
    }
  }, [state.recruiterId, loading.data, usersList, recruiterDefaultValue]);

  useEffect(() => {
    if (recruiterDefaultValue) {
      setState('recruiterData', recruiterDefaultValue);
    }
  }, [recruiterDefaultValue, teamMembersValue]);

  const isInvalid = useCallback(
    (key) => {
      const value = state[key];
      const touched = touchedFields[key];
      return (
        (isValidating === true && (value === '' || !value || (Array.isArray(value) && !value.length))) ||
        (touched && (value === '' || !value || (Array.isArray(value) && !value.length)))
      );
    },
    [touchedFields, isValidating]
  );

  const displayError = (fieldKey) => renderErrorField(isInvalid(fieldKey));
  const [isSet, setIsSet] = useState(false);

  useEffect(() => {
    dispatchToolkit(getDepartmentsOptions());
  }, []);

  useEffect(() => {
    if (!isSet && state.departmentId) {
      (async () => {
        try {
          const { items } = await browseDepartments({
            filter: {
              archived: false,
              id: state.departmentId
            }
          });
          if (isArray(items)) {
            dispatchToolkit(
              setDepartmentsOptions((state) =>
                uniqBy(
                  [
                    ...state,
                    ...items.map((item) => {
                      item.value = item.id;
                      item.label = item.name;
                      return item;
                    }),
                  ],
                  'value'
                )
              )
            );
          }
          setIsSet(true);
        } catch (error) {
          log(error);
        }
      })();
    }
  }, [state.departmentId]);

  const jobFunctionSelected = useMemo(() => {
    const foundJobFunction = jobFunctionsOptions.find((option) => option.value === state?.jobFunction);
    if (foundJobFunction) {
      return foundJobFunction;
    }

    return null;
  });

  return (
    <div
      style={{
        position: 'relative',
        zIndex,
      }}
    >
      <h3 className="line-behind">{t('edit-position.not-visible-position-page')}</h3>
      <Row className="position-form-holder">
        <Col md="5" style={{ zIndex }}>
          <FormGroup>
            <Label for="recruiter">{t('general.recruiter')} *</Label>
            <InputLoaderWrapper
              condition={recruiterDefaultValueLoading || loading.data}
              loaderStyles={{
                right: 18,
              }}
            >
              <SelectV2
                searchable
                searchKey="name"
                id="recruiter"
                resource="position-recruiter"
                defaultOptions={usersList}
                onSelect={([selected]) => {
                  setTouchedFields((state) => ({
                    ...state,
                    recruiterId: true,
                  }));

                  setState('recruiterId', selected?.id);
                  setState(
                    'teamMembers',
                    teamMembersValue.filter((item) => item.userId !== selected?.id)
                  );
                  setRecruiterDefaultValue(selected);
                }}
                value={recruiterDefaultValue ? [recruiterDefaultValue] : []}
                keys={['id', 'name']}
                invalid={isInvalid('recruiterId')}
                disabled={
                  loading.data ||
                  recruiterDefaultValueLoading ||
                  currentUser.permissionLevel === 0 ||
                  canEditTeamOrRecruit
                }
              />
            </InputLoaderWrapper>
            {displayError('recruiterId')}
          </FormGroup>
          <FormGroup>
            <Label for="team-members">{t('positions.team-members')}</Label>
            <InputLoaderWrapper
              condition={loading.data}
              loaderStyles={{
                right: 18,
              }}
            >
              <SelectV2
                clearable
                searchable
                searchKey="name"
                multiple
                id="team-members"
                resource="position-team-members"
                defaultOptions={usersList}
                onSelect={(selected) => {
                  setState(
                    'teamMembers',
                    selected.map((item) => {
                      return {
                        ...item,
                        id: item.teamMemberId || 0,
                        name: item.name,
                        positionId: state.positionId || 0,
                        userName: item.name,
                        userId: item.userId || item.id,
                      };
                    })
                  );
                }}
                filterOptionsFn={(options) => {
                  const alreadySelectedMembers = teamMembersValue.map(({ userId }) => userId);
                  return (options || []).filter(
                    (option) => option.id !== state.recruiterId && !alreadySelectedMembers.includes(option.id)
                  );
                }}
                value={teamMembersValue || []}
                keys={['id', 'name']}
                disabled={loading.data || canEditTeamOrRecruit}
                renderList={(items, onRemove) => <TeamMemberList items={items} onRemove={onRemove} />}
              />
            </InputLoaderWrapper>
          </FormGroup>
          <FormGroup>
            <Label for="job-function">
              {t('general.job-function')} {(!state.spontaneous && !state.isEnabledUptrailIntegration) && '*'}
            </Label>
            <InputLoaderWrapper
              condition={loading.data && state?.jobFunction}
              loaderStyles={{
                right: 18,
              }}
            >
              <SelectV2
                clearable
                searchable
                value={jobFunctionSelected ? [jobFunctionSelected] : []}
                onSelect={([selected]) => {
                  setTouchedFields((state) => ({
                    ...state,
                    jobFunction: true,
                  }));
                  setState('jobFunction', get(selected, 'value') || null);
                }}
                defaultOptions={jobFunctionsOptions}
                className={classnames({
                  'is-invalid': !state.spontaneous && !state.isEnabledUptrailIntegration && isInvalid('jobFunction'),
                })}
                disabled={loading.data}
                id="job-function"
                resource="position-job-function"
              />
            </InputLoaderWrapper>
            {!state.spontaneous && !state.isEnabledUptrailIntegration && displayError('jobFunction')}
          </FormGroup>
          <FormGroup>
            <Label for="employment-rate">{t('edit-position.employment-rate')}</Label>
            <Select
              value={employmentRateOptions.find((item) => item.value === state.employmentRate)}
              onChange={(selected) => {
                setState('employmentRate', selected ? selected.value : null);
              }}
              options={employmentRateOptions}
              isClearable
              id="employment-rate"
              resource="position-employment-rate"
            />
          </FormGroup>
          {!!(get(state, 'employmentRate') && state.employmentRate === 1) && (
            <FormGroup>
              <Label for="employment-rate-description">{t('edit-position.employment-rate-description')}</Label>
              <Input
                value={state.employmentRateDescription}
                onChange={(e) => {
                  setState('employmentRateDescription', e.target.value);
                }}
                autoComplete="off"
                type="textarea"
                onBlur={() => {
                  setTouchedFields((state) => ({
                    ...state,
                    employmentRateDescription: true,
                  }));
                }}
                invalid={isInvalid('employmentRateDescription')}
                id="employment-rate-description"
                resource="position-employment-rate-description-input"
              />
            </FormGroup>
          )}
          {!!departmentsOptions.length && (
            <FormGroup>
              <Label for="department">{t('general.department')}</Label>
              <InputLoaderWrapper
                condition={loading.data}
                loaderStyles={{
                  right: 18,
                }}
              >
                <Select
                  async
                  isClearable
                  callback={(value) => {
                    return browseDepartments({
                      filter: {
                        archived: false,
                        name: value
                      }
                    });
                  }}
                  value={departmentsOptions.find((department) => department.value === state.departmentId)}
                  keys={['id', 'name']}
                  onChange={(selected) => {
                    setTouchedFields((state) => ({
                      ...state,
                      id: true,
                    }));
                    setState('departmentId', get(selected, 'value') || null);
                    dispatchToolkit(setDepartmentsOptions((state) => uniqBy([...state, selected], 'value')));
                  }}
                  defaultOptions={departmentsOptions}
                  isDisabled={loading.data}
                  onMenuOpen={() => setZIndex(3)}
                  onMenuClose={() => setZIndex(2)}
                  id="department"
                  resource="position-department"
                />
              </InputLoaderWrapper>
            </FormGroup>
          )}
        </Col>
        <Col md="4">
          <FormGroup>
            <Label for="show-recruiter-info">{t('edit-position.show-recruiter-image')}</Label>
            <Switch
              checked={state.showRecruiterInfo}
              onChange={(state) => setState('showRecruiterInfo', state)}
              disabled={loading.data}
              id="show-recruiter-info"
              data-testid="position-recruiter-info-switch"
            />
          </FormGroup>
        </Col>
      </Row>
    </div>
  );
};

export default NotVisiblePosition;
