export const SalaryType = [
  {
    value: 1,
    label: 'edit-position.fixed-salary',
  },
  {
    value: 2,
    label: 'edit-position.fixed-salary-and-commission',
  },
  {
    value: 3,
    label: 'edit-position.only-commission',
  },
];

export const EmploymentRate = [
  {
    value: 0,
    label: 'edit-position.full-time',
  },
  {
    value: 1,
    label: 'edit-position.part-time',
  },
];

export const BottomMenuItems = [
  {
    label: 'general.position',
    component: 'VisiblePosition',
  },
  {
    label: 'general.pipeline-steps',
    component: 'ProcessSteps',
  },
  {
    label: 'general.screening',
    component: 'ScreeningQuestions',
  },
  {
    label: 'general.promote',
    component: 'Promote',
  },
];

const createCategory = (categoryId) =>
  JSON.stringify({
    id: 0,
    candidateCategoryId: categoryId,
    active: false,
    sendMailToCandidate: false,
    candidateEmailSubject: '',
    candidateEmailText: '',
    candidateEmailDelayHours: 0,
    notifiedUserIdJsonString: '[]',
    autoRejectCandidate: false,
  });

export const defaultFormData = {
  applySettings: null,
  positionId: 0,
  name: '',
  description: '',
  pictureUploaded: false,
  pictureFileExt: null,
  locationId: null,
  recruiterId: null,
  candidatesIdString: '',
  questionsJsonString: '[]',
  archived: false,
  publishDate: new Date(),
  applicationDeadline: '',
  hideAfterDeadline: true,
  spontaneous: false,
  hidden: false,
  publicLinkSuffix: '',
  categorySettingsAJsonString: createCategory(1),
  categorySettingsBJsonString: createCategory(2),
  categorySettingsCJsonString: createCategory(3),
  categorySettingsOthersJsonString: createCategory(0),
  picture: null,
  image: null,
  useAlternativeApplyForm: false,
  alternativeApplyFormLink: '',
  showDescription: false,
  teamMembers: [],
  pipelineSteps: [],
  publishOnAms: false,
  departmentId: null,
  salaryType: 0,
  employmentRate: null,
  employmentRateDescription: null,
  positionTypeId: null,
  positionTypeKey: '',
  positionCategoryKey: '',
  showRecruiterInfo: true,
  draft: false,
  preview: false,
  jobFunction: [],
  pipelineStepName: null,
  pitch: '',
  publishToMasterClient: true,
  pipelineStepsTemplate: null
};

export const deepLinkURL = (clientKey, positionId, customUrl) => {
  const protocol = 'https://';
  const URL = global?.appConfig?.deep_link_base_url;

  return `${customUrl || `${protocol}${clientKey}.${URL}`}${positionId ? `${positionId}-` : ''}`;
};
